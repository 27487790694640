import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import background from '../images/bluehexagon_faded.svg'
import { LocaleContext } from '../components/layout'
import WatchElementAnimate from '../components/watch-element-animate'
import PredictYourSales from '../components/solutions/predict-your-sales'
import SolutionTestimonial from '../components/solutions/solution-testimonial'
import IntroBackground from '../images/Software_image_forecasts.svg'
import Intro from '../components/home/intro'
import AIWork from '../components/services/ai-work'
import SEO from '../components/SEO/SEO'
import { withPreview } from 'gatsby-source-prismic'
import Check from '../images/Check.svg'
import LocalizedLink from '../components/localized-link'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'
import Industries from "../components/home/industries";
// styles
const HeroWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: 1230px 1405px;
  padding: 138px 0 40px 0;
  background-position: 200% 68%;
  @media (max-width: 640px) {
    background-size: 120% 100%;
    background-position: 100% 20%;
    padding-bottom: 0px;
  }

  li {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  li::before {
    content: '';
    display: block;
    margin-right: 12px;
    background-size: cover;
    background-image: url(${Check});
    width: 16px;
    height: 16px;
    padding: 0 9px;
  }
`
const ContentWrapper = styled.div`
  .testimonials {
    margin-bottom: 4rem;
  }
  .functionalities {
    .intro-description {
      p {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  .turquoise {
    display: block;
  }
  .industry-benefit {
    margin-bottom: 20px;
  }
  @media (max-width: 640px) {
    .functionalities {
      padding-top: 0;
    }
  }
`
// markup
const IndustryPage = ({
  data: { prismicIndustry },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <SEO
        title={prismicIndustry.data.meta_title}
        desc={prismicIndustry.data.meta_description}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div tw="grid md:grid-cols-2 md:gap-28">
            <div tw="whitespace-normal">
              <span tw="text-teal text-sm tracking-widest font-bold uppercase">
                {prismicIndustry.data.name}
              </span>
              <h1 tw="text-navy pt-2 md:pt-0">
                {prismicIndustry.data.headline.text}
              </h1>
              <p tw="text-lg text-ocean py-4">
                {prismicIndustry.data.subheadline.text}
                <div
                  dangerouslySetInnerHTML={{
                    __html: prismicIndustry.data.hero_benefits.html
                  }}
                  tw="text-navy mt-4 leading-10 text-lg font-black"></div>
              </p>
              {prismicIndustry.data.hero_button &&
                <LocalizedLink to="/request-meeting" tw="hidden md:block">
                  <LargeSolidTurquoiseButton tw="max-w-sm">
                    {prismicIndustry.data.hero_button}
                  </LargeSolidTurquoiseButton>
                </LocalizedLink>
              }
            </div>
            <div tw="mt-8 md:mt-0">
              <Img
                fluid={prismicIndustry.data.image.fluid}
                objectFit="contain"
              />
            </div>
            <div tw="md:hidden mt-4">
              <LocalizedLink to="/request-meeting">
                <LargeSolidTurquoiseButton tw="max-w-sm">
                  {i18n.buttons.meeting}
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
            </div>
          </div>
        </div>
      </HeroWrapper>
      <ContentWrapper>
        {prismicIndustry.data.body.map((b, index) => {
          switch (b.slice_type) {
            case 'benefits':
              return (
                <WatchElementAnimate
                  className={`.industry__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`industry__animated__${index}`}>
                    <PredictYourSales predict={b.primary} items={b.items} />
                  </div>
                </WatchElementAnimate>
              )
            case 'client_testimonial':
              return (
                <WatchElementAnimate
                  className={`.industry__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`industry__animated__${index}`}>
                    <SolutionTestimonial testimonial={b.primary} />
                  </div>
                </WatchElementAnimate>
              )
            case 'functionalities':
              return <Intro intro={b} bg={IntroBackground} />
            case 'lets_make_ai_work':
              return (
                <AIWork
                  ai={b.primary}
                  items={b.items}
                  flow={'reverse'}
                  type={'industry'}
                  i18n={i18n}
                />
              )
            case 'industries':
              return (
                  <Industries
                      industries={b.primary}
                      items={b.items}
                      format={'select'}
                      i18n={i18n}
                  />
              )
            default:
              return null
          }
        })}
      </ContentWrapper>
    </main>
  )
}

export default withPreview(IndustryPage)
export const pageQuery = graphql`
  query IndustryByUid($uid: String!, $locale: String!) {
    prismicIndustry(uid: { eq: $uid }, lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        subheadline {
          text
        }
        hero_benefits {
          html
        }
        hero_button
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        name
        meta_title
        meta_description
        body {
          ... on PrismicIndustryBodyBenefits {
            slice_type
            primary {
              top_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              benefits_headline
              benefits_description
            }
          }
          ... on PrismicIndustryBodyClientTestimonial {
            slice_type
            primary {
              text
              role
              name
              link {
                link_type
                type
                lang
                uid
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              button1
            }
          }
          ... on PrismicIndustryBodyFunctionalities {
            slice_type
            primary {
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              description {
                html
              }
              button
            }
          }
          ... on PrismicIndustryBodyLetsMakeAiWork {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                html
              }
            }
            items {
              description {
                html
              }
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicIndustryBodyIndustries {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              name {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
              benefit {
                html
              }
              link {
                url
              }
            }
          }
        }
      }
      uid
    }
  }
`
